.arkon-slide-banner {
  &__slider {
    width: 100%;
    overflow: hidden;

    .slick-track {
      display: flex;
      .item {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}